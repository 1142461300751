import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {UserAuthService} from '../../../../@core/user-auth.service';
import {take} from 'rxjs/operators';

const BST_VIEWS = [
    {value: 'GLOBAL', label: 'Global'},
    {value: 'ARSANA_NO_BST', label: 'Arsana-Only'},
    {value: 'BST', label: 'BroadStreet'},
    {value: 'BST_NO_ARSANA', label: 'BST-Only'},
];

@Component({
    selector: 'app-bst-view-select',
    templateUrl: './bst-view-select.component.html',
})
export class BstViewSelectComponent implements OnChanges {
    @Input() view: string = null;
    @Output() viewChange = new EventEmitter<string>();

    options = BST_VIEWS;
    hasBst: boolean;

    constructor(private userAuth: UserAuthService) {
        this.userAuth.hasBst$.pipe(take(1)).subscribe(x => this.hasBst = x);
    }

    ngOnChanges() {
        if (!this.view || this.view === 'GLOBAL') this.view = 'GLOBAL';
    }

    updateView(view: string) {
        this.view = view;
        this.viewChange.emit(view === 'GLOBAL' ? null : view);
    }
}
