<ng-container *ngIf="hasBst">
    <fal i="list" class="text-primary ml-3 mr-1" ngbTooltip="Patient Records"></fal>
    <ng-select [items]="options"
               [ngModel]="view"
               (ngModelChange)="updateView($event)"
               [clearable]="false"
               bindValue="value"
               bindLabel="label"
               style="min-width: 8em;"
               class="body-select flex-shrink-0">
    </ng-select>
</ng-container>
